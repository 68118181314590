// GameSelection.tsx
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { usePlayerName } from "../providers/PlayerNameContext";
import { useGameDispatch } from "../providers/GameContext";
import { useSocket } from "../providers/SocketContext";

type GameSummary = { gameId: string; state: string; playerCount: number };

function GameSelection() {
  const socket = useSocket();
  const playerName = usePlayerName();
  const gameDispatch = useGameDispatch();
  const [games, setGames] = useState<GameSummary[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.on("gameCreated", (games: GameSummary[]) => {
      setGames(games);
    });
  }, [socket]);

  if (!socket) {
    return null;
  }

  const joinGame = (gameName: string) => {
    // Here you can do any setup needed to join a game, then navigate to the gameplay
    gameDispatch({ type: "CREATE", payload: gameName });
    socket.emit("joinGame", gameName, playerName);
    console.log(`Joining ${gameName}...`);
    navigate(`/games/${gameName}`); // Assuming your game code or ID would be part of the URL or state in a real app
  };

  const newGame = () => {
    socket.emit("createGame");
  };

  return (
    <div className="container mx-auto">
      <div className="logo"></div>
      <div>
        <button className="new_game" onClick={newGame}>
          New Game
        </button>
      </div>
      <ul className="game_list">
        {games.map((game, index) => (
          <li className="join_panel" key={index}>
            <div className="heads">
              {Array.from({ length: game.playerCount }).map(
                (_, playerIndex) => (
                  <div
                    key={playerIndex}
                    className={`heads_icon heads_icon--icon_${
                      playerIndex % 10
                    }`}
                  ></div>
                )
              )}
            </div>
            <div
              className={`game_list__status game_list__status--${
                game.state === "lobby" ? "active" : "inactive"
              }`}
            ></div>
            <span>{game.gameId}</span>{" "}
            {game.state === "lobby" && (
              <button
                className="join_button"
                onClick={() => joinGame(game.gameId)}
              >
                Join
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GameSelection;
