import React, { useEffect } from "react";
import io from "socket.io-client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAudioPlayer, useGlobalAudioPlayer } from "react-use-audio-player";

import NameEntry from "./components/NameEntry";
import GameSelection from "./components/GameSelection";
import Gameplay from "./components/Gameplay";
import Dashboard from "./components/Dashboard";

import "./App.scss";
import Template from "./components/Template";
import {
  PlayerNameProvider,
  usePlayerName,
} from "./providers/PlayerNameContext";
import { SocketProvider } from "./providers/SocketContext";
import { GameProvider } from "./providers/GameContext";
import ErrorBoundary from "./ErrorBoundary";

const apiBaseUrl = process.env.API_BASE_URL || "https://api.sparksplex.com";

const socket = io(apiBaseUrl, {
  auth: (cb) =>
    cb(
      localStorage.getItem("playerName")
        ? { playerName: localStorage.getItem("playerName") }
        : {}
    ),
  reconnection: true, // defaults to true
  reconnectionDelay: 10000, // defaults to 1000
  reconnectionDelayMax: 10000, // defaults to 5000
});

const App = () => {
  const playerName = usePlayerName();
  const { setVolume } = useGlobalAudioPlayer();
  const { load: loadBackground } = useAudioPlayer();

  useEffect(() => {
    loadBackground(require("./audio/BackgroundAmbience2.mp3"), {
      initialVolume: 0.1,
      loop: true,
      autoplay: true,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => setVolume(0), 5000);
  }, []);

  return (
    <SocketProvider socket={socket}>
      <PlayerNameProvider>
        <GameProvider>
          <Template>
            <ErrorBoundary>
              <Router>
                <Routes>
                  <Route
                    path="/"
                    element={
                      playerName ? (
                        <Navigate replace to="/games" />
                      ) : (
                        <NameEntry />
                      )
                    }
                  />
                  <Route path="/games" element={<GameSelection />} />
                  <Route path="/games/:gameName?" element={<Gameplay />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="*"
                    element={!playerName ? <Navigate replace to="/" /> : <></>}
                  />{" "}
                  {/* Redirects any unknown route to root */}
                </Routes>
              </Router>
            </ErrorBoundary>
          </Template>
        </GameProvider>
      </PlayerNameProvider>
    </SocketProvider>
  );
};

export default App;
