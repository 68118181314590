// Import necessary hooks and components from React and React Router Dom
import React, { useContext, useState } from "react";
import { PlayerNameDispatchContext } from "../providers/PlayerNameContext";

function NameEntry() {
  const [tempPlayerName, setTempPlayerName] = useState("");
  const dispatch = useContext(PlayerNameDispatchContext);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!tempPlayerName) return;
        dispatch({ type: "SET_NAME", payload: tempPlayerName });
        window.location.href = "/games";
      }}
    >
      <input
        type="text"
        value={tempPlayerName ?? ""}
        onChange={(e) => setTempPlayerName(e.target.value)}
        placeholder="Your name"
      />
      <button type="submit"> Submit </button>
    </form>
  );
}

export default NameEntry;
