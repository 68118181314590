export interface Player {
    playerName: string,
    id: string,
    score: number,
    roundScore: number,
    words: Array<string>,
    guesses: Array<string>
    connected: boolean
}

export enum GameState {
    LOBBY = 'lobby',
    GENERATING_IMAGE = 'generating_image',
    GUESSING = 'guessing',
    ROUND_OVER = 'round_over',
    GAME_OVER = 'game_over',
    NOT_CONNECTED = 'not_connected'
}

export interface Game {
    gameId: string;
    players: Array<Player>;
    words: string[];
    usedWords: string[];
    round: number;
    state: GameState;
    image: string | null;
    roundTime: number;
    interestingFacts: { text: string, audio: Uint8Array[] }[] | null;
    minimumWords: number;
}